import { createApp } from 'vue'
import App from './App.vue'
import router from './routers' // 引入 router
import { createHead, VueHeadMixin } from '@unhead/vue'

// 引入 Vuetify
//import vuetify from './plugins/vuetify' // 引入 Vuetify 插件
import { createVuetify } from 'vuetify';
import 'vuetify/styles'; // 引入 Vuetify 樣式
//import '@mdi/font/css/materialdesignicons.css'
import * as components from 'vuetify/components'
import * as directives from 'vuetify/directives'

const app = createApp(App)


//Vuetify
// 創建 Vuetify 實例
const vuetify = createVuetify({
  components,
  directives
})
const head = createHead()
app.mixin(VueHeadMixin)
app.use(head)

app.use(router) // 使用路由
app.use(vuetify)
app.mount('#app')

