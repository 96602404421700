<template>
  <v-app>
    <v-app-bar scroll-behavior="hide" scroll-threshold="3" density="compact" >
      <v-img :src="require('@/assets/pneko_logo.png')" max-height="70%" contain/>
      
    </v-app-bar>
    <router-view /> <!-- 路由出口 -->
    <v-spacer class="pa-10"></v-spacer>
    <v-footer>
      <div class="pt-0">
        Copyright © 2025 Pneko.All rights reserved.
      </div>
    </v-footer>
  </v-app>
</template>

<script>
export default {
  name: 'App',
  components: {
  }
}
</script>

<style>
#app {
  font-family: Avenir, Helvetica, Arial, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  color: #2c3e50;
}
body {
  margin: 0;
}

/* Nav Bar Logo 樣式 */
.nav-logo {
  max-height: 34px; /* Logo 高度 */
  width: auto; /* 等比例縮放 */
  cursor: pointer; /* 鼠標懸停效果 */
}
</style>
