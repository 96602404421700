import { createRouter, createWebHistory } from 'vue-router'

// 定義路由規則
const routes = [
  {
    path: '/',
    name: 'Home',
    component: () => import('../views/blogs/PostView.vue'), // 使用 lazy loading
  },
  {
    path: '/:pathMatch(.*)*', // 通配符，捕獲所有未定義的路由
    name: 'NotFound',
    component: () => import('../views/blogs/PostView.vue'), // 使用 lazy loading
  },
]

// 創建路由實例
const router = createRouter({
  history: createWebHistory(), // 使用 HTML5 的 History 模式
  routes,
})

export default router
